import { collection, doc, getDoc, getDocs, getFirestore } from 'firebase/firestore'
import { useEffect, useState } from 'react'
import { IoCallOutline, IoLocationOutline, IoSearchSharp } from 'react-icons/io5'
import './StoreBranch.css'
import { StoreDetail } from '../App/App_Store_interface'
import { getAuth, signInAnonymously } from '@firebase/auth'
import { initializeApp } from 'firebase/app'
import GetInTouch from '../GetInTouch/GetInTouch'

export default function StoreBranches() {

    const [stores, setStores] = useState<Array<StoreDetail>>([])

    const [chainId] = window.location.pathname.split('/').splice(1)

    const [searchQuery, setSearchQuery] = useState('')

    useEffect(() => {
        initializeApp({
            apiKey: "AIzaSyDKhIM0h7ipv8oI32-VFB91iELRvzB9zK4",
            authDomain: "invoay-booking.firebaseapp.com",
            projectId: "invoay-booking",
            storageBucket: "invoay-booking.appspot.com",
            messagingSenderId: "926888529000",
            appId: "1:926888529000:web:320686d52fee33775dffdd",
            measurementId: "G-V8RPDGL417"
        })

        signInAnonymously(getAuth())
            .then(() =>
                Promise.all([
                    getDoc(
                        doc(
                            collection(
                                getFirestore(),
                                'chains',
                            ),
                            chainId,
                        ),
                    ),
                    getDocs(
                        collection(
                            getFirestore(),
                            `chains/${chainId}/stores`,
                        ),
                    )
                ])
            ).then((data) => {
                setStores(data[1].docs.map((d) => {
                    return {
                        ...d.data(),
                        aboutUsText: data[0].get('aboutUsText'),
                        id: d.id,
                    }
                }) as Array<StoreDetail>)
            })

    }, [chainId])

    if (stores.length === 0) {
        return <div className='loader'></div>
    }


    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            justifyContent: 'center',
            width: '100%',
        }}>
            <div style={{
                display: 'flex',
                flexDirection: 'row',
                justifyContent: 'center',
                width: '100%'
            }}>
                <img src={stores[0].logo} alt="store logo" height={272} style={{
                    borderRadius: '14px',
                }} />
            </div>


            <small style={{ padding: '22px' }}>Choose a location</small>

            <div id='searchBar' className='canvas radiusMax'
                style={{
                    margin: '0 14px'
                }} >
                <IoSearchSharp size={'22'} />
                <input
                    onChange={async e => setSearchQuery(e.target.value)}
                    type='text'
                    placeholder='search by name or location...' />
            </div>


            <div id='StoreBranch'>
                {stores
                    .map(e => e.storeTypes == 2 ? [{ ...e, storeTypes: 0 }, { ...e, storeTypes: 0 }] : e)
                    .flat()
                    .filter(store => {
                        if (searchQuery.length === 0) {
                            return true;
                        }
                        const searchLowerString = searchQuery.toLowerCase()

                        return store.name.toLowerCase().startsWith(searchLowerString) ||
                            store.address.line1.toLowerCase().includes(searchLowerString) ||
                            store.address.line2.toLowerCase().includes(searchLowerString) ||
                            store.address.line1.toLowerCase().startsWith(searchLowerString) ||
                            store.address.line2.toLowerCase().startsWith(searchLowerString)
                    })
                    .map((store, i) => (
                        <div
                            key={i}
                            className='storeBranchCard canvas'
                        >
                            <img
                                src={store.logo ?? ''}
                                alt='StoreImage'
                                width={142}
                            />
                            <h2> {store.name} </h2>
                            <a
                                href={`https://www.google.com/maps/@${store.address.lat},${store.address.long},${17}z`}
                                className='branchCardAddress'
                            >
                                <IoLocationOutline size={'32'} style={{
                                    gridRow: '1 / span 2',
                                    border: '0.7px solid skyBlue',
                                    borderRadius: '7px',
                                    padding: '4px',
                                }} />
                                <p> {store!.address.line1}</p> <br />
                                <p>{store.address.line2 ?? ''}</p>
                            </a>
                            {store.contactInfo.phoneNumber !== undefined &&
                                store.contactInfo.phoneNumber !== null &&
                                <a
                                    href={`tel://${store.contactInfo.phoneNumber}`}
                                    className='branchCardAddress'
                                >
                                    <IoCallOutline size={'32'} style={{
                                        gridRow: '1 / span 2',
                                        border: '0.7px solid skyBlue',
                                        borderRadius: '7px',
                                        padding: '4px',
                                    }} />
                                    <p> {store!.contactInfo.phoneNumber}</p>
                                </a>
                            }

                            <a
                                aria-label='Change Brach'
                                className='canvasDark'
                                style={{
                                    padding: '7px 14px',
                                    color: 'white',
                                    textAlign: 'center',
                                    fontWeight: '500',
                                    marginBottom: '14px',
                                }}
                                href={`https://${window.location.host}/${chainId}/${store.id}/${(store.storeTypes ?? 0) === 0 ? 'booking' : 'retail-booking'}`}
                            >
                                Book Now
                            </a>
                            <div id='storeMedia'>
                                {(
                                    store.bookingMedia.length !== 0 ? store.bookingMedia : store.retailBookingMedia
                                ).slice(0, 3).map((img, i) => {
                                    return <img
                                        key={i}
                                        src={img}
                                        alt='StoreImage'
                                        height={142}
                                    ></img>
                                })}
                            </div>
                        </div>

                    ))}

            </div >
            <a
                aria-label='Change Brach'
                className='canvasDark'
                style={{
                    padding: '14px',
                    margin: '14px',
                    color: 'white',
                    textAlign: 'center',
                    fontWeight: '500',
                    marginBottom: '14px',
                    // width: '1000px'
                }}
                href={`https://${window.location.host}/${window.location.pathname.split('/').at(1)}/aboutUs`}
            >
                About Us
            </a>
            <div style={{
                padding: '14px'
            }}>
                <GetInTouch storeDetail={stores[0]} isLargeScreen={true} />
            </div>

        </div>
    )
}
