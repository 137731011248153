import { atom } from "jotai"
import { Item, StoreDetail } from "./App_Store_interface"


// store boolean state for toggling between select services and staff/ date / time selection screen 
const showSelectServices = atom(true)

// toggle booking form on booking button click 
const showBookingDrawer = atom(false)

// store detail include name, time, category and employees info
const storeDetailAtom = atom<StoreDetail | null>(null)

// state object that store selected items/ services
const cartAtom = atom<Array<Item>>([])

// state object that store selected items/ services id to toggle services checkbox 
// const cartItemIds = atom<Array<string>>([])

// set item that have to remove from cart 
// then show a dialog to remove that item from cart
const removeItemFromCart = atom<Item | null>(null)

const employeeSelectionDialogAtom = atom<Item | null>(null)

const showTermsAndConditions = atom<boolean>(false)

// show appointment confirm message toggle
const showBookingSuccessMessageAtom = atom<boolean>(false)

export { showBookingDrawer, showSelectServices, storeDetailAtom, cartAtom, removeItemFromCart, showBookingSuccessMessageAtom, showTermsAndConditions, employeeSelectionDialogAtom }

