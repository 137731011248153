import { useAtom, useAtomValue, useSetAtom } from 'jotai'
import './EmployeeSelectionComp.css'
import { Item } from '../App/App_Store_interface'
import { cartAtom, employeeSelectionDialogAtom, storeDetailAtom } from '../App/App_state'
import { IoCheckmarkSharp } from 'react-icons/io5'
import { useState } from 'react'


export default function EmployeeSelectionModal(props: {
    item: Item,
}) {
    // set item that have to remove from cart 
    // then show a dialog to remove that item from cart
    const setEmployeeSelectionItem = useSetAtom(employeeSelectionDialogAtom)

    const [cart, addToCart] = useAtom(cartAtom)

    const storeDetail = useAtomValue(storeDetailAtom)

    const [selectedEmployees, setSelectedEmployees] = useState<Set<number>>(new Set())


    return (
        <div id='EmployeeSelectionDialog' className='canvas' >
            <h3 style={{ color: 'grey', padding: '14px 0' }}>Select Employees</h3>
            {
                storeDetail?.employees?.map(employee => {
                    return <button
                        aria-label='Add To Cart' onClick={() => {
                            if (selectedEmployees.has(employee.invoayId)) {
                                setSelectedEmployees(new Set([...Array.from(selectedEmployees).filter(e => e !== employee.invoayId)]))
                            } else {
                                setSelectedEmployees(new Set([...Array.from(selectedEmployees), employee.invoayId]))
                            }
                        }}
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            borderTop: '0.2px solid lightgray',
                            gap: '7px',
                            padding: '12px 0',
                            fontWeight: 'bold',
                        }}>
                        {selectedEmployees.has(employee.invoayId)
                            ? <div className='checkBox checkBox_check canvasDark'>
                                <IoCheckmarkSharp color='white' size={22} />
                            </div>
                            : <div className='checkBox'></div>}
                        {`${employee.firstName} ${employee.lastName ?? ''}`}
                    </button>
                })
            }
            <div>
                <button
                    style={{ backgroundColor: 'lightgrey', color: 'black' }}
                    aria-label='Cancel'
                    className='canvasButton'
                    onClick={() => setEmployeeSelectionItem(null)
                    }>Cancel</button>
                {
                    selectedEmployees.size === 0 ? <></> : <>
                        <div style={{ width: '4px' }}></div>
                        <button
                            aria-label='Confirm'
                            className='canvasDark'

                            style={{ textAlign: 'center', color: 'white' }}
                            onClick={() => {
                                addToCart([
                                    ...cart,
                                    {
                                        ...props.item,
                                        employeesId: Array.from(selectedEmployees),
                                    }
                                ]);

                                setEmployeeSelectionItem(null)
                            }}>ADD</button>
                    </>
                }
            </div>
        </div>
    )
}
