import { collection, getDocs, getFirestore, query, where } from 'firebase/firestore';
import { useAtomValue } from 'jotai'
import { useEffect, useState } from 'react'
import { IoChevronUpSharp } from 'react-icons/io5';
import ServiceTile from '../ServiceTile/ServiceTile'

import './CategoryGridView.css';
import { storeDetailAtom } from '../App/App_state';
import { Category, Item } from '../App/App_Store_interface';
import { searchQueryAtom, searchResultAtom } from '../SearchBar/SearchBarState';


// select items small screen layout
export default function CategoryGridView() {

  // catagories of store items
  const categories = useAtomValue(storeDetailAtom)!.categories

  const [showItemsLoading, setShowItemsLoading] = useState(false);

  // selected category index
  const [showCategoryIndex, setShowCategoryIndex] = useState<number | null>(null)

  // items for selected category
  const [items, setItems] = useState<Array<Array<Item>>>(Array(categories.length).fill([]))

  const searchResult = useAtomValue(searchResultAtom)

  const searchQuery = useAtomValue(searchQueryAtom);

  useEffect(() => {

    if (showCategoryIndex === null) {
      return
    }
    setShowItemsLoading(true);

    if (items[showCategoryIndex!].length === 0) {
      // get store id from url path
      const [chainId, storeId, storeType] = window.location.pathname.split('/').splice(1)

      const selectedCategory = categories[showCategoryIndex!]

      // get items from firestore where category id === selected category id
      getDocs(
        query(
          collection(
            getFirestore(),
            `chains/${chainId}/stores/${storeId}/${storeType === 'retail-booking' ? 'productCategories' : 'serviceCategories'}/${selectedCategory.id}/items`
          ),
          where('isVisibleInFirestore', '==', true)
        )
      ).then((d) => {
        const data = [...items]
        data[showCategoryIndex!] = d.docs.map((s) => {
          return {
            ...s.data(),
            id: s.id,
          } as Item;
        }
        )

        setShowItemsLoading(false)
        setItems(data)
      })
    }

  }, [showCategoryIndex])

  if (searchQuery.length > 0) {

    return (
      <div className='canvas' id='searchResultBox'>
        {searchQuery.length < 2
          ? <div className='noItemsMessage'>Search box must contain at least three letters...</div>
          : searchResult?.length === 0
            ? <div className='noItemsMessage'>No Item for this search query.</div>
            : searchResult!
              .filter((item) => item.isActive && item.isVisibleInFirestore)
              .map((item, j) => <ServiceTile key={j} service={item} />)}
      </div>
    )
  }

  return (
    <div className='categoriesGridView'>

      {categories
        .map((category, i) => {
          return (
            <div
              key={i}
              className={showCategoryIndex === i ? 'categorySelected canvas' : ''}
              id={category.name.replaceAll(' ', '-')}
            >
              < a
                className={`category canvas ${showCategoryIndex === i ? 'sticky' : ''}`}
                aria-label='Services'
                href={`#${category.name.replaceAll(' ', '-')}`}
                onClick={() => setShowCategoryIndex(showCategoryIndex === i ? null : i)}
              >
                {
                  category.image === undefined || category.image === null || category.image.length === 0
                    ? <h3>{category.name[0]}</h3>
                    : <img
                      src={category.image}
                      alt={category.name}
                      onError={source => source.currentTarget.parentElement!.innerHTML = `<h2 style ='color: grey'>${category.name[0]}</h2><h3>${category.name}</h3>`}
                    />
                }
                <h3> {category.name} </h3>
                {showCategoryIndex === i && <IoChevronUpSharp size={27} />}
              </a>

              {
                showCategoryIndex === i
                &&
                <>
                  <SubCategoriesGridView categoryId={category.id} categoryHref={`#${category.name.replaceAll(' ', '-')}`} />
                  {

                    items[i]
                      .filter((item) => item.isActive && item.isVisibleInFirestore)
                      .map((item, j) => (
                        <ServiceTile key={j} service={item} />
                      ))
                  }
                </>
              }

              {showCategoryIndex === i && items[i].length === 0 && showItemsLoading && <div className='loaderSmall'></div>}
              {showCategoryIndex === i && items[i].length === 0 && !showItemsLoading && <div className='noItemsMessage'>No Item for this category.</div>}
            </div>
          )
        })}

    </div >

  )
}

function SubCategoriesGridView(params: {
  categoryId: string,
  categoryHref: string,
}) {

  const [subCategories, setSubCategories] = useState(Array<Category>(7))

  const [showItemsLoading, setShowItemsLoading] = useState(false);

  // selected category index
  const [showCategoryIndex, setShowCategoryIndex] = useState<number | null>(null)

  // items for selected category
  const [items, setItems] = useState<Array<Array<Item>>>(Array(subCategories.length).fill([]))

  useEffect(() => {
    const [chainId, storeId, storeType] = window.location.pathname.split('/').splice(1)

    getDocs(
      query(
        collection(
          getFirestore(),
          `chains/${chainId}/stores/${storeId}/${storeType === 'retail-booking' ? 'productCategories' : 'serviceCategories'}/${params.categoryId}/subCategories`
        ),
        where('isVisibleInFirestore', '==', true)
      )
    ).then((d) => {

      setSubCategories(d.docs.map((s) => {
        return {
          ...s.data(),
          id: s.id,
        } as Category;
      }
      ))

    })

  }, [])

  useEffect(() => {

    if (showCategoryIndex === null) {
      return
    }
    setShowItemsLoading(true);

    if (items[showCategoryIndex!].length === 0) {
      // get store id from url path
      const [chainId, storeId, storeType] = window.location.pathname.split('/').splice(1)

      const selectedCategory = subCategories[showCategoryIndex!]

      // get items from firestore where category id === selected category id
      getDocs(
        query(
          collection(
            getFirestore(),
            `chains/${chainId}/stores/${storeId}/${storeType === 'retail-booking' ? 'productCategories' : 'serviceCategories'}/${params.categoryId}/subCategories/${selectedCategory.id}/items`
          ),
          where('isVisibleInFirestore', '==', true)
        )
      ).then((d) => {
        const data = [...items]
        data[showCategoryIndex!] = d.docs.map((s) => {
          return {
            ...s.data(),
            id: s.id,
          } as Item;
        }
        )

        setShowItemsLoading(false)
        setItems(data)
      }).catch((e) => {
        console.error(e)
      })
    }

  }, [showCategoryIndex])

  const isLargeScreen = window.matchMedia("(min-width: 972px)").matches

  return <div
    className='categoriesGridView'
    style={subCategories.length < 3 ? {
      padding: '7px',
      gridTemplateColumns: isLargeScreen ? 'repeat(3, 32%)' : 'repeat(2, 49%)',
    } : {
      padding: '7px',
    }}
  >
    {
      subCategories
        .map((subCategory, i) => {
          return (
            <div
              style={{
                border: '0.7px solid skyBlue',
                borderRadius: '14px'
              }}
              key={i}
              className={showCategoryIndex === i ? 'categorySelected canvas' : ''}
              id={subCategory.name.replaceAll(' ', '-')}
            >
              < a
                className={`category canvas ${showCategoryIndex === i ? 'sticky' : ''}`}
                style={{
                  borderTop: '0.7px solid skyBlue',
                  padding: showCategoryIndex === i ? '7px' : '0',
                  top: '127px',
                  zIndex: '42',
                }}
                aria-label='Services'
                href={params.categoryHref}
                onClick={() =>
                  setShowCategoryIndex(showCategoryIndex === i ? null : i)
                }
              >
                {
                  subCategory.image === undefined || subCategory.image === null || subCategory.image.length === 0
                    ? <h2>{subCategory.name}</h2>
                    : <img
                      src={subCategory.image}
                      alt={subCategory.name}
                      onError={source => source.currentTarget.parentElement!.innerHTML = `<h3>${subCategory.name}</h3><h3>${subCategory.name} </h3>`}
                    />
                }
                <h3> {subCategory.name} </h3>
                {showCategoryIndex === i && <IoChevronUpSharp size={27} />}
              </a>



              {
                showCategoryIndex === i
                && items[i]
                  .filter((item) => item.isActive && item.isVisibleInFirestore)
                  .map((item, j) => (
                    <ServiceTile key={j} service={item} />
                  ))
              }

              {showCategoryIndex === i && items[i].length === 0 && showItemsLoading && <div className='loaderSmall'></div>}
              {showCategoryIndex === i && items[i].length === 0 && !showItemsLoading && <div className='noItemsMessage'>No Item for this category.</div>}
            </div>
          )
        })
    }

  </div >
}

